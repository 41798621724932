* {
  font-family: sans-serif;
}

body {
  padding-top: 60px;
}

.summary-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #0c1f4b;
  min-height: 20vh;
  border-radius: 25px;
  box-shadow: 0 5px 1em grey;
}

.home-highlight-col {
  background-color: #00d1d1;
  border-radius: 25px;
}

.home-highlight-row {
  border-radius: 25px;
  box-shadow: 0 5px 1em lightgrey;
}

.rotate-button {
  padding: .5em;
  color: dimgrey;
}

.rotate-button:hover {
  background-color: lightgrey;
  cursor: pointer;
}
